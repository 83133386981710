import deepmerge from 'deepmerge'
import type { ReactNode } from 'react'
import type React from 'react'
import { createContext, useContext, useRef } from 'react'
import { useStore as useRawStore } from 'zustand'

import type { KPI } from '@/shared/components/SpotPrices/commons'
import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'

import { createSpotPricesStore } from './state'
import type { KPIs, SpotPricesStore, State, StateValues } from './state'

const SpotPricesStoreContext = createContext<SpotPricesStore | null>(null)

type ConsumptionComparisonStoreProviderProps = {
  children: ReactNode
  initialValues: Partial<StateValues>
  initialKPIs: Record<keyof KPIs, Partial<KPI>> | undefined
}

export const SpotPricesStoreProvider: React.FC<ConsumptionComparisonStoreProviderProps> = ({
  children,
  initialValues,
  initialKPIs = {},
}) => {
  const storeRef = useRef<SpotPricesStore>()
  if (!storeRef.current) {
    storeRef.current = createSpotPricesStore((defaultValues) => ({
      ...initialValues,
      KPIs: deepmerge(defaultValues.KPIs, initialKPIs),
    }))
  }

  return (
    <SpotPricesStoreContext.Provider value={storeRef.current}>
      {children}
    </SpotPricesStoreContext.Provider>
  )
}

export const useStore = <T,>(selector: (state: State) => T): T => {
  const store = useContext(SpotPricesStoreContext)
  if (!store) {
    throw logError(new UiError('Missing SpotPricesStoreProvider in the tree'))
  }
  return useRawStore(store, selector)
}
