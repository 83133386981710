import { ServerActionNetworkError } from './serverActionNetworkError'

export const withNetworkErrorHandling = async <T extends (...args: never[]) => Promise<S>, S>(
  action: T,
  ...params: Parameters<T>
): Promise<{
  networkError?: ServerActionNetworkError
  actionResult?: Awaited<ReturnType<T>>
}> => {
  try {
    const result = await action(...params)
    return {
      actionResult: result as Awaited<ReturnType<T>>,
    }
  } catch (error) {
    const actionParams = JSON.stringify(params)
    return {
      networkError: new ServerActionNetworkError(
        `Network error with server action ${actionParams}`,
        error,
      ),
    }
  }
}
