type CommonKeys<T> = keyof T
type ExtractAllUnionKeys<T> = T extends Record<string, unknown> ? keyof T : never
type NonCommonKeys<T> = Exclude<ExtractAllUnionKeys<T>, CommonKeys<T>>

type UnionToIntersection<U> = (U extends unknown ? (x: U) => void : never) extends (
  x: infer I,
) => void
  ? I
  : never

export type FlattedUnion<T> = {
  [key in CommonKeys<T>]: T[key]
} & {
  [key in NonCommonKeys<T>]?: UnionToIntersection<T>[key]
}

/**
 * The purpose of this function is to type union of objects as a single object with discriminating fields being
 * presented as optionals, whilst common fields being marked as they're
 * e.g.
 * for object of type A = B | C
 * where
 * type B = {
 *   size: number;
 *   color?: string;
 *   name: string;
 *   fullName?: string | null;
 * }
 * type C = {
 *   size: number;
 *   color?: string;
 *   nickname: string;
 *   email?: string | null;
 * }
 * you get object typed as {
 *   size: number;
 *   color?: string;
 *   name?: string;
 *   fullName? string | null;
 *   nickname?: string;
 *   email? string | null;
 * }
 * @param object
 */

export const safeUnionFlatten = <T>(object: T) => {
  return object as FlattedUnion<T>
}
