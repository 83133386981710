import { DateTime } from 'luxon'

import { type GranularityOption } from '@/logged-in/components/Energy/commons'
import type { TimeSeriesData, TimeSeriesQueryOutputData } from '@/shared/graphql/queries/queryTypes'
import type {
  CostType,
  Currency,
  DeliverySiteCategory,
  EnergyType,
  PriceAreaCode,
} from '@/shared/graphql/schema/commonBackend/graphql'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

import { MockedSpotPrices } from './spotGraph'

const BaseData = {
  meteringPointNo: '123',
  measurementUnit: 'unit',
  temperatureUnit: 'unit',
  priceUnit: 'kr/kWh',
  costUnit: 'SEK' as Currency,
  deliverySiteCategory: 'consumption' as DeliverySiteCategory,
}

const costTypes: CostType[] = ['ELCERT_AMOUNT', 'SPOT_VARIABLE_AMOUNT', 'VAR_AMOUNT']

const MockEnergyValues = [
  {
    value: 872.77,
  },
  {
    value: 1431.06,
  },
  {
    value: 1202.46,
  },
  {
    value: 972.01,
  },
  {
    value: 849.66,
  },
  {
    value: 622.79,
  },
  {
    value: 493.42,
  },
  {
    value: 572.72,
  },
  {
    value: 871.1,
  },
  {
    value: 812.67,
  },
  {
    value: 998.23,
  },
  {
    value: 1032.68,
  },
  {
    value: 872.77,
  },
  {
    value: 1431.06,
  },
  {
    value: 1202.46,
  },
  {
    value: 972.01,
  },
  {
    value: 849.66,
  },
  {
    value: 622.79,
  },
  {
    value: 493.42,
  },
  {
    value: 572.72,
  },
  {
    value: 871.1,
  },
  {
    value: 872.67,
  },
  {
    value: 1198.23,
  },
  {
    value: 932.68,
  },
  {
    value: 622.79,
  },
  {
    value: 493.42,
  },
  {
    value: 472.72,
  },
  {
    value: 371.1,
  },
  {
    value: 272.67,
  },
  {
    value: 298.23,
  },
  {
    value: 232.68,
  },
]

export const mockPriceArea: PriceAreaCode = 'SE1'
export const mockCostUnit: Currency = 'SEK'
const timeZone = getTimeZoneForPriceAreaCode(mockPriceArea)
export const mockLocale = 'sv-SE'

export const populateMockedData = (
  mockValue: number | null = null,
  isZeroConsumption = false,
  index = 0,
) => {
  const spotPrice = index < 25 ? MockedSpotPrices[index].price : MockedSpotPrices[index - 8].price
  return {
    energy: [
      {
        value: (() => {
          if (!mockValue || (isZeroConsumption && index % 4 === 0)) {
            return 0
          }
          return mockValue
        })(),
        type: 'Energy' as EnergyType,
      },
    ],
    cost: costTypes.map((type) => ({
      value: 0,
      total: mockValue ? (mockValue * spotPrice) / 100 : 0,
      type,
    })),
    temperature: {
      //avg: Math.floor(Math.random() * 10), //atm not in use
    },
    price: {
      value: spotPrice / 100,
      total: 0,
      vatPercentage: 0,
    },
  }
}

export const MockedStoryBookNow = DateTime.fromObject({
  year: 2023,
  month: 8,
  day: 21,
  hour: 12,
}).setZone(getTimeZoneForPriceAreaCode(mockPriceArea))

const DayData = (isZeroConsumption = false) => {
  const reversedArray = MockEnergyValues.slice().reverse()
  return Array.from({ length: 24 }, (_, i) => ({
    atUTC: DateTime.now().set({ hour: i }).toUTC().toISO(),
    ...populateMockedData(reversedArray[i].value / 24, isZeroConsumption, i),
  })) satisfies TimeSeriesData[]
}

const MonthData = () => {
  const daysInMonth = DateTime.now().daysInMonth
  return Array.from({ length: daysInMonth }, (_, i) => ({
    atUTC: DateTime.now()
      .set({ day: i + 1 })
      .toUTC()
      .toISO(),
    ...populateMockedData(MockEnergyValues[i].value / 12),
  })) satisfies TimeSeriesData[]
}

const YearData = () => {
  return Array.from({ length: 12 }, (_, i) => ({
    atUTC: DateTime.now()
      .setZone(timeZone)
      .set({ month: i + 1 })
      .toUTC()
      .toISO()!,
    ...populateMockedData(MockEnergyValues[i].value),
  })) satisfies TimeSeriesData[]
}

export const generate = (
  granularity: GranularityOption,
  isZeroConsumption = false,
): TimeSeriesQueryOutputData => {
  switch (granularity) {
    case 'Year':
      return {
        ...BaseData,
        series: YearData(),
      }
    case 'Month':
      return {
        ...BaseData,
        series: MonthData(),
      }
    case 'Day':
    default:
      return {
        ...BaseData,
        series: DayData(isZeroConsumption),
      }
  }
}
