'use client'

import { useTranslations } from 'next-intl'
import type React from 'react'

import { WidgetHeading } from '@/logged-in/components/WidgetHeading'

import { useStore } from '../StoreProvider'

/**
 * Heading component for SpotGraph component - displays title and info icon with popover
 */
export const Heading: React.FC = () => {
  const priceArea = useStore((s) => s.priceArea)
  const tooltip = useStore((s) => s.tooltip)

  const t = useTranslations('spotPrices')

  const heading = priceArea === 'FI' ? t('heading') : `${t('heading')} ${priceArea}`

  return <WidgetHeading data-testid="spot-price-date-heading" heading={heading} popover={tooltip} />
}
